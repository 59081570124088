import { PagedResult } from '@ftr/contracts/api/core/response/PagedResult'
import { AbstractBuilder } from '@ftr/foundation'

const PAGE_SIZE = 10

/**
 * A helper for if you only need to mock the items, instead of the page meta.
 */
export class PagedResultBuilder<T> extends AbstractBuilder<PagedResult<T>> {
  static fromArray<T>(items: T[], page = 1): PagedResult<T> {
    return new PagedResultBuilder<T>().withItems(items, page).build()
  }

  constructor() {
    super({
      items: [],
      meta: {
        number: 1,
        totalItems: 2,
        size: PAGE_SIZE,
      },
    })
  }

  withItems(items: T[], page = 1): this {
    const start = (page - 1) * PAGE_SIZE
    Object.assign(this.result, {
      items: items.length > PAGE_SIZE ? items.slice(start, start + PAGE_SIZE) : items,
      meta: {
        ...this.result.meta,
        number: page,
        totalItems: items.length,
      },
    })
    return this
  }
}
