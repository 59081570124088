import { Injectable } from '@angular/core'
import { JudgeRole } from '@ftr/annotations-contracts'
import { AnnotationsApiClientFactory } from '@ftr/api-annotations'
import { Uuid } from '@ftr/contracts/type/shared'
import { RemoteData } from '@ftr/foundation'
import { CourtSystemRegionCache } from '@ftr/ui-court-system'
import { Action, createSelector, Selector, State, StateContext, StateOperator } from '@ngxs/store'
import { patch } from '@ngxs/store/operators'
import { memoize } from 'lodash-es'
import { firstValueFrom, Observable, of } from 'rxjs'
import {
  AddJudgeRoleCommand,
  FetchJudgeRolesListPageCommand,
  InitialiseJudgeRolesListCommand,
  UpdateJudgeRolesListCommand,
} from './judge-roles.commands'
import { JudgeRolesListAccessForbidden } from './judge-roles.event'
import { JudgeRolesListInstanceStateModel, JudgeRolesListStateModel, JudgeRoleStatus } from './judge-roles.model'

export const JUDGE_ROLES_PAGE_SIZE = 100

export function defaultJudgeRolesListState(): JudgeRolesListStateModel {
  return { instances: {} }
}

@State<JudgeRolesListStateModel>({
  name: 'judgeRolesState',
  defaults: defaultJudgeRolesListState(),
})
@Injectable()
export class JudgeRolesState {
  constructor(
    private readonly regionCache: CourtSystemRegionCache,
    private readonly annotationsApiFactory: AnnotationsApiClientFactory,
  ) {}

  @Selector()
  static allInstanceStates(state: JudgeRolesListStateModel): Record<Uuid, JudgeRolesListInstanceStateModel> {
    return state?.instances ?? {}
  }

  static readonly judgeRoles = memoize(
    (courtSystemId: string): ((sourceStates: ReturnType<typeof JudgeRolesState.allInstanceStates>) => JudgeRole[]) =>
      createSelector([JudgeRolesState.allInstanceStates], sourceStates => {
        return sourceStates[courtSystemId]?.judgeRoles ?? []
      }),
    courtSystemId => courtSystemId,
  )

  static readonly pageResult = memoize(
    (
      courtSystemId: string,
    ): ((
      sourceStates: ReturnType<typeof JudgeRolesState.allInstanceStates>,
    ) => JudgeRolesListInstanceStateModel['pageResultRemote']) =>
      createSelector([JudgeRolesState.allInstanceStates], sourceStates => {
        return sourceStates[courtSystemId]?.pageResultRemote
      }),
    courtSystemId => courtSystemId,
  )

  static readonly status = memoize(
    (
      courtSystemId: string,
    ): ((sourceStates: ReturnType<typeof JudgeRolesState.allInstanceStates>) => JudgeRoleStatus) =>
      createSelector([JudgeRolesState.allInstanceStates], sourceStates => {
        return sourceStates[courtSystemId]?.status ?? []
      }),
    courtSystemId => courtSystemId,
  )

  @Action(InitialiseJudgeRolesListCommand)
  private initialiseJudgeRolesList(
    { setState, getState, dispatch }: StateContext<JudgeRolesListStateModel>,
    { courtSystemId }: InitialiseJudgeRolesListCommand,
  ): Observable<void> | undefined {
    const instance = getState().instances[courtSystemId]
    if (instance?.pageResultRemote.isSuccess() || instance?.pageResultRemote.isLoading()) {
      return
    }

    setState(
      makeInstancePatch(courtSystemId, {
        judgeRoles: [],
        pageResultRemote: RemoteData.notAsked(),
      }),
    )

    return dispatch(new FetchJudgeRolesListPageCommand(courtSystemId))
  }

  @Action(UpdateJudgeRolesListCommand)
  private updateJudgeRolesList(
    { setState }: StateContext<JudgeRolesListStateModel>,
    { courtSystemId, judgeRoles }: UpdateJudgeRolesListCommand,
  ): void {
    const updatedJudgeRoles: JudgeRole[] = judgeRoles.map((role, index) => ({ ...role, sequenceNumber: index + 1 }))
    setState(makeInstancePatch(courtSystemId, { judgeRoles: updatedJudgeRoles }))

    // TODO(EN-172): Update request
  }

  @Action(FetchJudgeRolesListPageCommand)
  private async fetchJudgeRolesPage(
    { getState, setState, dispatch }: StateContext<JudgeRolesListStateModel>,
    { courtSystemId }: FetchJudgeRolesListPageCommand,
  ): Promise<void> {
    const { pageResultRemote } = getState().instances[courtSystemId]
    if (pageResultRemote.isLoading()) {
      return
    }
    setState(
      makeInstancePatch(courtSystemId, {
        pageResultRemote: RemoteData.loading(),
      }),
    )

    // TODO: EN-193 - Handle pagination
    const offset = 0
    const limit = JUDGE_ROLES_PAGE_SIZE

    const region = (await firstValueFrom(this.regionCache.getOrFetchCourtSystem(of(courtSystemId)))).region
    const apiClient = this.annotationsApiFactory.build(region)

    try {
      const result = await apiClient.judgeRoles.listJudgeRoles({ query: { courtSystemId, offset, limit } })
      if (result.status === 200) {
        setState(
          makeInstancePatch(courtSystemId, {
            judgeRoles: result.body.items,
            pageResultRemote: RemoteData.success({ hasMore: result.body.items.length === limit }),
            status: { type: 'loaded' },
          }),
        )
      } else {
        setState(
          makeInstancePatch(courtSystemId, {
            judgeRoles: [],
            pageResultRemote: RemoteData.failure(new Error('Failed to fetch judge roles', { cause: result.body })),
            status: { type: 'loaded' },
          }),
        )
        if (result.status === 403) {
          dispatch(new JudgeRolesListAccessForbidden(courtSystemId))
        }
      }
    } catch (err) {
      setState(
        makeInstancePatch(courtSystemId, {
          judgeRoles: [],
          pageResultRemote: RemoteData.failure(new Error('Failed to fetch judge roles', { cause: err })),
          status: { type: 'loaded' },
        }),
      )
    }
  }

  @Action(AddJudgeRoleCommand)
  private async addJudgeRole(
    { dispatch, setState }: StateContext<JudgeRolesListStateModel>,
    { courtSystemId, roleName }: AddJudgeRoleCommand,
  ): Promise<void> {
    const region = (await firstValueFrom(this.regionCache.getOrFetchCourtSystem(of(courtSystemId)))).region
    const apiClient = this.annotationsApiFactory.build(region)
    setState(
      makeInstancePatch(courtSystemId, {
        status: { type: 'saving' },
      }),
    )

    const result = await apiClient.judgeRoles.createJudgeRole({ body: { courtSystemId, name: roleName } })
    if (result.status === 201) {
      setState(
        makeInstancePatch(courtSystemId, {
          status: { type: 'saved' },
        }),
      )
    } else {
      const errorMessage = result.status === 409 ? 'This role already exists.' : 'An error occurred. Please try again.'
      setState(
        makeInstancePatch(courtSystemId, {
          status: { type: 'failed', error: new Error(errorMessage) },
        }),
      )
    }
    dispatch(new FetchJudgeRolesListPageCommand(courtSystemId))
  }
}

function makeInstancePatch(
  instanceId: string,
  instancePartial: Partial<JudgeRolesListInstanceStateModel>,
): StateOperator<JudgeRolesListStateModel> {
  return patch({
    instances: patch({
      [instanceId]: patch(instancePartial),
    }),
  })
}
