// eslint-disable-next-line max-classes-per-file
import { CreateJudgeRequest } from '@ftr/annotations-contracts'
import { Uuid } from '@ftr/contracts/type/shared'

export const JUDGE_PROFILES_ACTION_SCOPE = '[Judge Profiles]'

export class AddJudgeProfileCommand {
  static readonly type = `${JUDGE_PROFILES_ACTION_SCOPE} Add Judge Profile`
  constructor(readonly judgeProfile: CreateJudgeRequest) {}
}

export class InitialiseJudgeProfilesListCommand {
  static readonly type = `${JUDGE_PROFILES_ACTION_SCOPE} Initialise Judge Profile List`
  constructor(readonly courtSystemId: Uuid) {}
}

export class FetchJudgeProfilesListCommand {
  static readonly type = `${JUDGE_PROFILES_ACTION_SCOPE} Fetch Judge Profile List`
  constructor(readonly courtSystemId: Uuid) {}
}
