/*
Constants for button properties
note: danger is deprecated use destructiveSecondary instead
*/
export const BUTTON_COLORS = [
  'primary',
  'secondary',
  'grey',
  'white',
  'destructive',
  'destructiveSecondary',
  'danger',
] as const
export const BUTTON_DISPLAY_TYPES = ['text', 'inline-text', 'icon'] as const
export const BUTTON_SIZES = ['extra-small', 'tiny', 'small', 'large'] as const
export const BUTTON_DIRECTIONS = ['forward', 'backward'] as const

/* Type definitions for button properties */
export type ButtonColor = (typeof BUTTON_COLORS)[number]
export type ButtonDisplayType = (typeof BUTTON_DISPLAY_TYPES)[number]
export type ButtonSize = (typeof BUTTON_SIZES)[number]
export type ButtonDirection = (typeof BUTTON_DIRECTIONS)[number]
