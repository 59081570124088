/* eslint-disable max-classes-per-file */
import { LocalTimeRange, Uuid } from '@ftr/contracts/type/shared'
import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { LocalDate, LocalTime } from '@js-joda/core'
import { HearingSectionFormModel } from '../../lib'
import { RealTimeSttMarkerEditSource } from '../../types'

export class CreateHearingSectionCommand {
  static readonly type = '[Annotations] Create Hearing Section'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: Uuid,
    readonly startTime: LocalTime,
    readonly recordingDate: LocalDate,
    readonly locationId: Uuid,
    readonly triggerSource: 'right-click stt' | 'playback clock' | 'live clock' | 'upcoming hearing',
    readonly title: string | null,
    readonly caseReference: string | null,
  ) {}
}

export class UpdateHearingSectionCommand {
  static readonly type = '[Annotations] Update Hearing Section'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly hearingSectionId: Uuid,
    readonly hearingSection: HearingSectionFormModel,
    readonly recordingDate: LocalDate,
    readonly locationId: Uuid,
  ) {}
}

export class AcceptAutoGeneratedHearingSectionCommand {
  static readonly type = '[Annotations] Accept auto generated hearing Section'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly hearingSectionId: Uuid,
  ) {}
}

export class DeleteHearingSectionCommand {
  static readonly type = '[Annotations] Delete Hearing Section'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly id: Uuid,
    readonly courtSystemId: Uuid,
    readonly source?: RealTimeSttMarkerEditSource,
  ) {}
}

export class FetchHearingSectionsCommand {
  static readonly type = '[Annotations] Fetch Hearing Sections'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: Uuid,
  ) {}
}

export class ListenForHearingUpdatesCommand {
  static readonly type = '[Annotations] Listen for Hearing Section Updates'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: Uuid,
  ) {}
}

export class StopListeningForHearingSectionUpdatesCommand {
  static readonly type = '[Annotations] Stop Listening for Hearing Section Updates'
  constructor(readonly playbackKey: RealTimePlaybackKey) {}
}

export class UpdateAnnotationBoundariesCommand {
  static readonly type = '[Annotations] Update Annotation Boundaries'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly annotationBoundaries: LocalTimeRange[],
  ) {}
}

export class UpdateAnnotationsLiveLocalTimeCommand {
  static readonly type = '[Annotations] Update Annotations Live LocalTime'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly liveLocalTime: LocalTime | undefined,
  ) {}
}

export class SetHearingSectionToOngoingCommand {
  static readonly type = '[Annotations] Set Hearing Section To Ongoing'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly hearingSectionId: Uuid,
    readonly recordingDate: LocalDate,
    readonly locationId: Uuid,
  ) {}
}

export class StopOngoingHearingForRecordingCommand {
  static readonly type = '[Annotations] Stop Ongoing Hearing For Recording'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly recordingDate: LocalDate,
    readonly locationId: Uuid,
  ) {}
}
