<div #outerCrumbs class="breadcrumbs-container">
  <div #innerCrumbs class="breadcrumbs-inner-container">
    <ftr-breadcrumbs-display
      [breadcrumbs]="breadcrumbs()"
      [collapse]="shouldCollapse()"
      [clampLeaf]="clampLeaf()"
      [clampLink]="clampLink()"
      [expandLink]="flexExpandLinkNode()"
    ></ftr-breadcrumbs-display>
  </div>
  <div #measurementCrumbs class="breadcrumbs-inner-measurement" aria-hidden="true" [style.visibility]="'hidden'">
    <ftr-breadcrumbs-display [breadcrumbs]="breadcrumbs()" [collapse]="false"></ftr-breadcrumbs-display>
  </div>
</div>
