import { Injectable } from '@angular/core'
import { Judge } from '@ftr/annotations-contracts'
import { AnnotationsApiClientFactory } from '@ftr/api-annotations'
import { PagedResultBuilder } from '@ftr/api-shared'
import { PagedResult } from '@ftr/contracts/api/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { RemoteData } from '@ftr/foundation'
import { CourtSystemRegionCache } from '@ftr/ui-court-system'
import { Action, createSelector, Selector, State, StateContext, StateOperator } from '@ngxs/store'
import { patch } from '@ngxs/store/operators'
import { memoize } from 'lodash-es'
import { firstValueFrom, Observable, of } from 'rxjs'
import {
  AddJudgeProfileCommand,
  FetchJudgeProfilesListCommand,
  InitialiseJudgeProfilesListCommand,
} from './judge-profiles.commands'
import { JudgeProfilesListInstanceStateModel, JudgeProfilesListStateModel } from './judge-profiles.model'

export const JUDGE_PROFILES_PAGE_SIZE = 100

const emptyJudgeProfiles = PagedResultBuilder.fromArray([])
export function defaultJudgeProfilesListStateModel(): JudgeProfilesListStateModel {
  return { instances: {} }
}

@State<JudgeProfilesListStateModel>({
  name: 'judgeProfilesState',
  defaults: defaultJudgeProfilesListStateModel(),
})
@Injectable()
export class JudgeProfilesState {
  constructor(
    private readonly regionCache: CourtSystemRegionCache,
    private readonly annotationsApiFactory: AnnotationsApiClientFactory,
  ) {}

  @Selector()
  static allInstanceStates(state: JudgeProfilesListStateModel): Record<Uuid, JudgeProfilesListInstanceStateModel> {
    return state?.instances ?? {}
  }

  static readonly judgeProfiles = memoize(
    (
      courtSystemId: string,
    ): ((sourceStates: ReturnType<typeof JudgeProfilesState.allInstanceStates>) => PagedResult<Judge>) =>
      createSelector([JudgeProfilesState.allInstanceStates], sourceStates => {
        return sourceStates[courtSystemId]?.judgeProfiles ?? emptyJudgeProfiles
      }),
    courtSystemId => courtSystemId,
  )

  static readonly judgeProfile = memoize(
    (
      courtSystemId: string,
      judgeId: string,
    ): ((sourceStates: ReturnType<typeof JudgeProfilesState.allInstanceStates>) => Judge | undefined) =>
      createSelector([JudgeProfilesState.allInstanceStates], sourceStates =>
        sourceStates[courtSystemId]?.judgeProfiles?.items.find(profile => profile.id === judgeId),
      ),
    (courtSystemId, judgeId) => [courtSystemId, judgeId].join('-'),
  )

  static readonly pageResult = memoize(
    (
      courtSystemId: string,
    ): ((
      sourceStates: ReturnType<typeof JudgeProfilesState.allInstanceStates>,
    ) => JudgeProfilesListInstanceStateModel['pageResultRemote']) =>
      createSelector([JudgeProfilesState.allInstanceStates], sourceStates => {
        return sourceStates[courtSystemId]?.pageResultRemote ?? RemoteData.notAsked()
      }),
    courtSystemId => courtSystemId,
  )

  static readonly addResult = memoize(
    (
      courtSystemId: string,
    ): ((
      sourceStates: ReturnType<typeof JudgeProfilesState.allInstanceStates>,
    ) => JudgeProfilesListInstanceStateModel['addResultRemote']) =>
      createSelector([JudgeProfilesState.allInstanceStates], sourceStates => {
        return sourceStates[courtSystemId]?.addResultRemote ?? RemoteData.notAsked()
      }),
    courtSystemId => courtSystemId,
  )

  @Action(InitialiseJudgeProfilesListCommand)
  private initialiseJudgeProfilesList(
    { setState, getState, dispatch }: StateContext<JudgeProfilesListStateModel>,
    { courtSystemId }: InitialiseJudgeProfilesListCommand,
  ): Observable<void> | undefined {
    setState(makeInstancePatch(courtSystemId, { addResultRemote: RemoteData.notAsked() }))

    const instance = getState().instances[courtSystemId]
    if (instance?.pageResultRemote?.isSuccess() || instance?.pageResultRemote?.isLoading()) {
      return
    }

    setState(
      makeInstancePatch(courtSystemId, {
        judgeProfiles: emptyJudgeProfiles,
        pageResultRemote: RemoteData.notAsked(),
      }),
    )

    return dispatch(new FetchJudgeProfilesListCommand(courtSystemId))
  }

  @Action(FetchJudgeProfilesListCommand)
  private async fetchJudgeProfiles(
    { getState, setState }: StateContext<JudgeProfilesListStateModel>,
    { courtSystemId }: FetchJudgeProfilesListCommand,
  ): Promise<void> {
    const { pageResultRemote } = getState().instances[courtSystemId]
    if (pageResultRemote.isLoading()) {
      return
    }
    setState(
      makeInstancePatch(courtSystemId, {
        pageResultRemote: RemoteData.loading(),
      }),
    )

    const offset = 0
    const limit = JUDGE_PROFILES_PAGE_SIZE

    const region = (await firstValueFrom(this.regionCache.getOrFetchCourtSystem(of(courtSystemId)))).region
    const apiClient = this.annotationsApiFactory.build(region)

    try {
      const result = await apiClient.judges.listJudges({ query: { courtSystemId, offset, limit } })
      const isSuccess = result.status === 200

      setState(
        makeInstancePatch(courtSystemId, {
          judgeProfiles: isSuccess
            ? {
                items: result.body.items,
                meta: {
                  totalItems: result.body.total,
                  number: Math.round(offset / JUDGE_PROFILES_PAGE_SIZE) + 1,
                  size: JUDGE_PROFILES_PAGE_SIZE,
                },
              }
            : emptyJudgeProfiles,
          pageResultRemote: isSuccess
            ? RemoteData.success({ hasMore: result.body.items.length === limit })
            : RemoteData.failure(new Error('Failed to fetch judge profiles', { cause: result.body })),
        }),
      )
    } catch (err) {
      setState(
        makeInstancePatch(courtSystemId, {
          judgeProfiles: emptyJudgeProfiles,
          pageResultRemote: RemoteData.failure(new Error('Failed to fetch judge profiles', { cause: err })),
        }),
      )
    }
  }

  @Action(AddJudgeProfileCommand)
  private async addJudgeProfile(
    { setState }: StateContext<JudgeProfilesListStateModel>,
    { judgeProfile }: AddJudgeProfileCommand,
  ): Promise<void> {
    const { courtSystemId } = judgeProfile
    const region = (await firstValueFrom(this.regionCache.getOrFetchCourtSystem(of(courtSystemId)))).region
    const apiClient = this.annotationsApiFactory.build(region)
    setState(makeInstancePatch(courtSystemId, { addResultRemote: RemoteData.loading() }))

    const { status, body } = await apiClient.judges.createJudge({ body: judgeProfile })

    const isSuccess = status === 201
    if (isSuccess) {
      setState(
        makeInstancePatch(courtSystemId, {
          pageResultRemote: RemoteData.notAsked(), // make stale
          addResultRemote: RemoteData.success(body),
        }),
      )
    } else {
      setState(
        makeInstancePatch(courtSystemId, {
          addResultRemote: RemoteData.failure(new Error('Failed to add judge profile')),
        }),
      )
    }
  }
}

function makeInstancePatch(
  instanceId: string,
  instancePartial: Partial<JudgeProfilesListInstanceStateModel>,
): StateOperator<JudgeProfilesListStateModel> {
  return patch({
    instances: patch({
      [instanceId]: patch(instancePartial),
    }),
  })
}
