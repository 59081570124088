<div
  [class]="className ?? ''"
  [ngClass]="{
    'filter-list-card': true,
    'filter-list-card--interactive': interactive,
    'filter-list-card--compact': displayMode === 'COMPACT',
  }"
  tabindex="0"
  role="button"
  (click)="handleOnClick($event)"
  (keyup.enter)="handleOnEnter($event)"
  (keydown.arrowleft)="setFocusToPrevious($event)"
  (keydown.arrowright)="setFocusToNext($event)"
  (keydown.arrowUp)="setFocusToAbove($event)"
  (keydown.arrowDown)="setFocusToBelow($event)"
>
  <ng-content></ng-content>
</div>
