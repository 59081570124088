/* eslint-disable max-classes-per-file */
import { Directive, Input, Signal, TemplateRef } from '@angular/core'
import { ApiResult, RemoteData } from '../../lib/remote-data'

interface ImplicitContext<T> {
  $implicit: T
  [index: string]: any
}

export type TypedTemplateRef<T> = TemplateRef<ImplicitContext<T>>

@Directive({
  selector: '[ftrTypedContext]',
  standalone: true,
})
export class TypedContextDirective<T> {
  /** The value whose type will be used as the template context type.  Will unwrap RemoteData, ApiResult, Signal and Array types if provided, otherwise the value type will be used.  */
  @Input()
  ftrTypedContext?: ApiResult<T> | Signal<RemoteData<T>> | RemoteData<T> | Signal<T[]> | Signal<T> | T[] | T

  static ngTemplateContextGuard<T>(_dir: TypedContextDirective<T>, _ctx: unknown): _ctx is ImplicitContext<T> {
    return true
  }
}

@Directive({
  selector: '[ftrTypedContextArrayOf]',
  standalone: true,
})
export class TypedContextArrayOfDirective<T> {
  /** The array value whose single element type will be used as the template context type.  Will unwrap RemoteData, ApiResult and Signal types */
  @Input()
  ftrTypedContextArrayOf?: ApiResult<T[]> | Signal<RemoteData<T[]>> | RemoteData<T[]> | Signal<T[]> | T[]

  static ngTemplateContextGuard<T>(_dir: TypedContextArrayOfDirective<T>, _ctx: unknown): _ctx is ImplicitContext<T> {
    return true
  }
}

@Directive({
  selector: '[ftrTypedContextValueOf]',
  standalone: true,
})
export class TypedContextValueOfDirective<T> {
  /** The value whose type will be used as the template context type.  Will unwrap RemoteData, ApiResult and Signal types */
  @Input()
  ftrTypedContextValueOf?: ApiResult<T> | Signal<RemoteData<T>> | RemoteData<T> | Signal<T> | T

  static ngTemplateContextGuard<T>(_dir: TypedContextValueOfDirective<T>, _ctx: unknown): _ctx is ImplicitContext<T> {
    return true
  }
}
