<span
  class="badge badge--color-{{ color }} badge--{{ modifier }} badge--{{ widthModifier }}"
  [class.badge--compact]="compact"
  [style]="{ maxWidth: maxWidth }"
  ftrTooltip
  [message]="tooltipLabel ?? ''"
  [forceDisplayState]="!!tooltipLabel ? undefined : 'hide'"
  [showDash]="false"
  alignment="center"
  [position]="tooltipPosition"
  [attr.aria-label]="customAriaLabel ? customAriaLabel : text ? text : 'Badge'"
>
  @if (icon) {
    <ftr-icon [icon]="icon" size="Text"></ftr-icon>
  }
  @if (text) {
    <span class="badge__text" [class.badge__text--compact]="compact">{{ text }}</span>
  }
</span>
