import { Injectable } from '@angular/core'
import { HearingAnnotationsUpdatedMessage } from '@ftr/contracts/message/annotations'
import {
  AutomaticAnnotationsHearingIdentificationCompleted,
  AutomaticAnnotationsHearingIdentificationFailed,
  AutomaticAnnotationsHearingIdentificationSkipped,
  AutomaticAnnotationsHearingIdentificationStarted,
} from '@ftr/contracts/regional-api/automatic-annotations'
import {
  JoinAudioSegmentHearingAnnotationsRoomRequest,
  JoinRecordingHearingAnnotationsRoomRequest,
} from '@ftr/contracts/socket'
import { Region } from '@ftr/contracts/type/court/Region'
import { Message, Uuid } from '@ftr/contracts/type/shared'
import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { assertUnreachable } from '@ftr/foundation'
import { ClassConstructor } from '@ftr/serialization'
import { merge, Observable } from 'rxjs'
import { SocketService } from '../socket'

type JoinHearingAnnotationsRoomRequest =
  | JoinRecordingHearingAnnotationsRoomRequest
  | JoinAudioSegmentHearingAnnotationsRoomRequest

export type AutomaticAnnotationJobUpdate =
  | AutomaticAnnotationsHearingIdentificationStarted
  | AutomaticAnnotationsHearingIdentificationSkipped
  | AutomaticAnnotationsHearingIdentificationCompleted
  | AutomaticAnnotationsHearingIdentificationFailed
@Injectable({
  providedIn: 'root',
})
export class RealTimeLiveHearingsService {
  constructor(private readonly socketService: SocketService) {}

  observeLiveHearingUpdates(
    joinHearingAnnotationsRoomRequest: JoinHearingAnnotationsRoomRequest,
    onErrorJoiningRoom?: () => void,
  ): Observable<HearingAnnotationsUpdatedMessage> {
    return this.listenToHearingAnnotationUpdates(joinHearingAnnotationsRoomRequest, onErrorJoiningRoom)
  }

  observeAutomaticAnnotationJobUpdates(
    joinHearingAnnotationsRoomRequest: JoinHearingAnnotationsRoomRequest,
    onErrorJoiningRoom?: () => void,
  ): Observable<AutomaticAnnotationJobUpdate> {
    return merge(
      this.listenForMessage(
        joinHearingAnnotationsRoomRequest,
        AutomaticAnnotationsHearingIdentificationStarted,
        onErrorJoiningRoom,
      ),
      this.listenForMessage(
        joinHearingAnnotationsRoomRequest,
        AutomaticAnnotationsHearingIdentificationSkipped,
        onErrorJoiningRoom,
      ),
      this.listenForMessage(
        joinHearingAnnotationsRoomRequest,
        AutomaticAnnotationsHearingIdentificationCompleted,
        onErrorJoiningRoom,
      ),
      this.listenForMessage(
        joinHearingAnnotationsRoomRequest,
        AutomaticAnnotationsHearingIdentificationFailed,
        onErrorJoiningRoom,
      ),
    )
  }

  private listenToHearingAnnotationUpdates(
    joinHearingAnnotationsRoomRequest: JoinHearingAnnotationsRoomRequest,
    onErrorJoiningRoom?: () => void,
  ): Observable<HearingAnnotationsUpdatedMessage> {
    return this.listenForMessage(
      joinHearingAnnotationsRoomRequest,
      HearingAnnotationsUpdatedMessage,
      onErrorJoiningRoom,
    )
  }

  private listenForMessage<T extends Message>(
    joinHearingAnnotationsRoomRequest: JoinHearingAnnotationsRoomRequest,
    constructor: ClassConstructor<T>,
    onErrorJoiningRoom?: () => void,
  ): Observable<T> {
    return this.socketService.onMessage(joinHearingAnnotationsRoomRequest, constructor, onErrorJoiningRoom)
  }
}

export function buildJoinHearingAnnotationsRoomRequest(
  playbackKey: RealTimePlaybackKey,
  courtSystemId: Uuid,
  courtSystemRegion: Region,
  authToken: string,
): JoinHearingAnnotationsRoomRequest | undefined {
  const sharedJoinRequestDetails = { courtSystemId, region: courtSystemRegion, auth: authToken }

  switch (playbackKey.type) {
    case 'audio-segment':
      return playbackKey.recordingId
        ? {
            ...sharedJoinRequestDetails,
            type: 'audio-segment-hearing-annotations',
            recordingId: playbackKey.recordingId,
            audioSegmentId: playbackKey.audioSegmentId,
          }
        : undefined
    case 'hearing':
      return undefined
    case 'recording':
      return {
        ...sharedJoinRequestDetails,
        type: 'recording-hearing-annotations',
        recordingId: playbackKey.recordingId,
      }
    default:
      assertUnreachable(playbackKey)
  }
}
