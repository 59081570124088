import { CommonModule } from '@angular/common'
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { ValidationErrorHintDirective } from '../../directives'

let nextId = 0

export enum CheckboxMode {
  Bistate = 'bi-state',
  Tristate = 'tri-state',
}

/**
 * A wrapper for an input checkbox element, including rendering of elements essential to validation display.
 */
@Component({
  selector: 'ftr-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css'],
  host: { class: 'checkbox input input--checkbox' },
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ValidationErrorHintDirective],
})
export class CheckboxComponent implements OnInit {
  @Input() id = `ftr-checkbox-${nextId++}`
  @Input() name?: string
  @Input() label?: string
  @Input() labelTemplate: TemplateRef<unknown> | null = null
  @Input() summary?: string
  @Input() summaryTemplate: TemplateRef<unknown> | null = null
  @Input() fullWidth: boolean = false
  @Input() isTitleBold = false
  /**
   * Label to be used to display validation. If not set, this defaults to <code>label</code>.
   */
  @Input() validationLabel?: string
  @Input() required = false
  @Input() control: UntypedFormControl
  @Input() highlightError: Observable<boolean>
  @Input() mode: CheckboxMode = CheckboxMode.Bistate
  @Input() testId?: string
  hasValue = false
  indeterminate = false

  @HostBinding('class.input--highlight') highlight = false
  @ViewChild('elm') elm: ElementRef | undefined

  get labelledBy(): string {
    return `${this.id}Label${this.summary ? ` ${this.id}Summary` : ''}`
  }

  ngOnInit(): void {
    this.onChange(this.control.value)
    this.control.valueChanges.subscribe(value => {
      this.onChange(value)
    })
    if (this.highlightError) {
      this.highlightError.subscribe(value => (this.highlight = value))
    }
  }

  setIndeterminate(indeterminateStatus: boolean): void {
    if (this.mode === CheckboxMode.Tristate) {
      this.indeterminate = indeterminateStatus
      if (this.elm?.nativeElement) {
        this.elm.nativeElement.indeterminate = indeterminateStatus
      }
    }
  }

  onChange(value: string): void {
    this.hasValue = !!value
  }
}
