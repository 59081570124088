/* eslint-disable max-classes-per-file */
import { Uuid } from '@ftr/contracts/type/shared'
import { RecordingsFilterLabelData, RecordingsFilterType, RecordingsFilterValueData } from './recordings-filter.model'

export class SetRecordingsFilterStateAction {
  static readonly type = '[RecordingsFilter] Patch Recordings Filter Value State'

  constructor(
    public courtSystemId: Uuid,
    public filterValues: RecordingsFilterValueData,
    public filterLabels: RecordingsFilterLabelData = {},
    public generateLabelTypes: RecordingsFilterType[] = [],
    public patch: boolean = true,
  ) {}
}

export class ClearRecordingsFilterStateAction {
  static readonly type = '[RecordingsFilter] Clear Recordings Filter State'
}

export class ClearRecordingsFilterTypeStateAction {
  static readonly type = '[RecordingsFilter] Clear Recordings Filter Type State'
  constructor(public filterType: RecordingsFilterType) {}
}

export class SyncRecordingsFilterState {
  static readonly type = '[RecordingsFilter] Sync Recordings Filter State'

  constructor(
    public courtSystemId: Uuid,
    public filterValues: RecordingsFilterValueData,
  ) {}
}
