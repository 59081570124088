import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { AutomaticAnnotationJobUpdate } from '@ftr/ui-user'

export class UpdateAutomaticAnnotationJobAction {
  static readonly type = '[Annotations] Update Automatic Annotation Job'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: string,
    readonly update: AutomaticAnnotationJobUpdate,
  ) {}
}
